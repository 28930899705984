.f-card {
	display: grid;
	grid-template-columns: 300px;
	grid-template-rows: 115px 100px 280px;
	background-color: transparent;
	width: 300px;
}


.f-image {
	grid-column: 1;
	grid-row: 1/3;
	border-radius: 50%;
	border-width: 10px;
	border-style: solid; 
    border-color:#1c1d21;
	z-index: 1;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 170px; 
	
}

.f-inner-card {
	grid-column: 1;
	grid-row: 2/4;
	border-radius: 10px;
	transition: transform 0.6s;
	transform-style: preserve-3d; 
}

.f-front {
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 10px;
	text-align: center;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-style: double;
	border-color: #f7f7f7; 
	background-color: black;
	opacity: 0.5;
	border-width: 3px;
}

.f-back {
	width: 100%;
	height: 100%;	
	position: absolute;
	border-radius: 10px;
	text-align: center;
	transform: rotateY(180deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-style: double;
	border-color: #f7f7f7;
	background-color: black;
	opacity: 0.5;
}
.f-card:hover .f-inner-card {
	transform: rotateY(180deg);
}
.f-name {
	margin-top: 100px;
	font-size: 20px;
	color: white;
	font-family: cursive;

}

.f-position {
	font-size: 30px;
	margin-top: 0;
	font-weight: 700;
	color: white;
	font-family: cursive;

}

.f-bio {
	font-style: italic;
	font-size: 20px;
	color: white;
	font-family: cursive;
}
.b-about {
	margin-top: 100px;
	font-size: 20px;
	font-weight: 500;
	color: white;

}

.b-social-icons {
	padding-left: 0;
	margin-bottom: 5px;
	list-style: none;
	margin: 0 auto;
}
.b-social-icons li {
	display: inline-block;
	margin-bottom: 4px;
}
.b-social-icons a {
	background-color: #eceeef;
	color: #818a91;
	font-size: 16px;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	margin-right: 8px;
	border-radius: 100%;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.b-social-icons a:active,
.b-social-icons a:focus,
.b-social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
}
.b-social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.b-social-icons a.facebook:hover {
	background-color: #3b5998;
}
.b-social-icons a.twitter:hover {
	background-color: #00aced;
}
.b-social-icons a.linkedin:hover {
	background-color: #007bb6;
}
.b-social-icons a.instagram:hover {
	background-color: #e95950;
}
