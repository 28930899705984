.divSvg {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: black;
}

#layer3 {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(
		180deg,
		black 81%,
		#ffffff 91%,
		rgb(255, 255, 255)
	);
	-webkit-animation: divsvg1 4s linear normal;
	animation: divsvg1 4s linear normal;
}
#logo {
	position: absolute;
	top: 40%;
	left: 25%;
	transform-origin: center center;
	animation: upDown 3s linear infinite 7s;
	width: 50%;
	animation: fill 5s ease forwards 4s;
}

#logo path {
	stroke: white;
	stroke-opacity: 1;
}

#logo path {
	stroke-dasharray: 12434.6650390625;
	stroke-dashoffset: 12434.6650390625;
	animation: line-anim 8s ease-in-out forwards;
}

@keyframes line-anim {
	to {
		stroke-dashoffset: 0;
	}
}

#shadow {
	position: absolute;
	top: -5%;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 50%;
	background: -webkit-linear-gradient(
		right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	clip-path: polygon(50% 0%, 100% 80%, 100% 99%, 0 99%, 0 79%);
	-webkit-clip-path: polygon(50% 0%, 100% 80%, 100% 99%, 0 99%, 0 79%);
	-webkit-animation: blinker 4s ease normal;
	animation: blinker 4s ease normal;
}

#divContainingShawdow {
	transform-origin: top;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-animation: ray_anim 3s linear normal 2s;
	animation: ray_anim 3s linear normal 2s;
}
@keyframes fill {
	0% {
		fill: transparent;
	}
	100% {
		fill: white;
	}
}
@keyframes lumino {
	0%,
	18%,
	20%,
	50.1%,
	60%,
	65%,
	80.1%,
	90%,
	92.1%,
	100% {
		stroke: #0e3742;
		text-shadow: none;
	}
	18.1%,
	20.1%,
	30%,
	50%,
	60.1%,
	65%,
	80.1%,
	90%,
	92.1%,
	100% {
		stroke: #fff;
		text-shadow: 0 0 10px #03bcf4, 0 0 20px #03bcf4, 0 0 40px #03bcf4,
			0 0 80px #03bcf4, 0 0 160px #03bcf4;
	}
}
@keyframes ray_anim {
	0% {
		transform: rotate(0deg) translateZ(0);
	}
	25% {
		transform: rotate(4deg) translateZ(0);
	}
	50% {
		transform: rotate(0deg) translateZ(0);
	}
	75% {
		transform: rotate(-4deg) translateZ(0);
	}
	100% {
		transform: rotate(0deg) translateZ(0);
	}
}

@keyframes blinker {
	0%,
	8%,
	16%,
	24%,
	32%,
	40%,
	48%,
	56% {
		opacity: 0%;
	}
	4%,
	12%,
	20%,
	28%,
	36%,
	44%,
	52%,
	60% {
		opacity: 20%;
	}
	80% {
		opacity: 23%;
	}
	90% {
		opacity: 27%;
	}
	100% {
		opacity: 31%;
	}
}
@keyframes divsvg1 {
	0%,
	8%,
	16%,
	24%,
	32%,
	40%,
	48%,
	56% {
		opacity: 0;
	}
	4%,
	12%,
	20%,
	28%,
	36%,
	44%,
	52%,
	60%,
	80%,
	90%,
	100% {
		opacity: 1;
	}
}
@keyframes upDown {
	33% {
		transform: translate(0, 10px);
	}
	66% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(0, -10px);
	}
}
@media all and (max-width: 986px) {
	#logo {
		width: 65%;
		height: 50%;
		top: 30%;
	}
	.divSvg {
		height: 50%;
	}
}
