.poster {
	/* width: 100vw; */
	margin-left:100px; 
	align-items: center;
	 
}

.pos-name
{
	text-align: center;
	font-size: 26px;
	font-weight: bold;
}

.pos-imag {  
	margin-top: 20px;
	height: 75vh; 
	width: 85%;
	border-radius: 3%;
	display: block;
	margin-left: auto;
	margin-right: 11%;
	animation-name: imagepreshift;
	animation-duration: 0.25s;
  }

.imshift
{
	margin-right:1%;
	animation-name: imageshift;
	animation-duration: 0.25s;
}  

@keyframes imageshift {
	from {
		margin-right:11%;
	}
}

@keyframes imagepreshift {
	from {
		margin-right:1%;
	}
}

.img-footer
{
	display:flex;
	flex-flow: row wrap;
	justify-content:space-between;
}

.pos-but
{
	 margin-right:12%;
	 height:50px;
	 width:100px;
	 padding: 10px;
	 margin-top:12px;
	 cursor: pointer;
	 background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%);
	 color: #fff;
	 outline: 0;
	 border: none;
	 border-radius: 10px; 
	 animation-name: butpreshift;
	 animation-duration: 0.25s;
}

.but-shift
{
	margin-right:1%;
	animation-name: butshift;
	animation-duration: 0.25s;
}

@keyframes butshift {
	from {
		margin-right:12%;
	}
}

@keyframes butpreshift {
	from {
		margin-right:1%;
	}
}
.gd-social-icons {
	margin-left: 30px;
	margin-right: auto;
	margin-bottom: 5px;
	margin-top:10px;
	list-style: none; 
	animation-name: nashift;
	animation-duration: 0.25s;
}

.socshift
{
	margin-left:155px;
	animation-name: napreshift;
	animation-duration: 0.25s
}

@keyframes nashift {
	from {
		margin-left: 155px;
	}
	 
}

@keyframes napreshift {
	from {
		margin-left: 30px;
	}
	 
}

.gd-social-icons li {
	display: inline-block;
	margin-bottom: 15px;
	padding: 7px;
}
.gd-social-icons li.title {
	text-transform: uppercase;
	color: #96a2b2;
	font-weight: 700;
	font-size: 13px;
}

.gd-social-icons a {
	font-size: 16px;
	background-color:white;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	border-radius: 100%;
	border: 2px solid;
	border-color: grey;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.gd-social-icons a.gd-facebook  {
	color: #3b5998;
}
.gd-social-icons a.gd-twitter  {
	 color: #00aced; 
}
.gd-social-icons a.gd-linkedin  {
	color: #007bb6; 
}
.gd-social-icons a.gd-instagram {
	 color: #e95950; 
}

.gd-social-icons a:active,
.gd-social-icons a:focus,
.gd-social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
	 
}
.gd-social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.gd-social-icons a.gd-facebook:hover {
	background-color: #3b5998;
}
.gd-social-icons a.gd-twitter:hover {
	background-color: #00aced;
}
.gd-social-icons a.gd-linkedin:hover {
	background-color: #007bb6;
}
.gd-social-icons a.gd-instagram:hover {
	background-color: #e95950;
}

.upbut
{
  transform-style: flat;
  transition: all 250ms ease-out;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(128, 128, 128, 0.1) inset;
 
}

.upbut::before, 
.upbut::after
{
	content: "";
	position: absolute;
	z-index: -2;
	transition: all 250ms ease-out;
}

.upbut::before
{ 
	top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
}

.upbut:hover
{  
	box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
}