
.phoneviewcategories{
    display:none;
}
@media screen and (max-width: 960px) {
    .phoneviewcategories{
        display:list-item;
    }
    body {
        font-family: sans-serif;
        font-size: 1em;
        color: #333;
        background: #f7f7f7;
      }
      .phoneviewcategories h1 {
        color: #0074D9;
        }
        
        .phoneviewcategories ul,
        li {
          padding: 0;
          margin: 0;
          list-style: none;
        }
        
        .phoneviewcategories ul {
          margin: 2em 0;
        }
        
        .phoneviewcategories li {
          margin: 1em;
          margin-left: 3em;
        }
        
        .phoneviewcategories li:before {
          content: '\f006';
          font-family: 'FontAwesome';
          float: left;
          margin-left: -1.5em;
          color: #0074D9;
        }
  }