@import url(https://fonts.googleapis.com/css?family=Special+Elite);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);
.site-footer {
	background :linear-gradient(
		90deg,
		rgb(28, 27, 27) 0%,
		rgb(26, 23, 23) 100%
	);
	color: #f0f9ff;
	margin-bottom: 0px;
}

.foot-row-first 
{
	padding-top:40px;
	display:-webkit-flex;
	display:flex;
	-webkit-justify-content:space-around;
	        justify-content:space-around;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
}

.single-cta i {
	color: #ff5e14;
	font-size: 30px;
	float: left;
	margin-top: 8px;
  }
  .cta-text {
	padding-left: 15px;
	display: inline-block;
  }
  .cta-text h4 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 2px;
  }
  .cta-text span {
	color: #757575;
	font-size: 15px;
  }

.follow-text
{
	text-align: center;
	font-size: 30px;
	color: white;
	margin-top:40px;
	
}  

.social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 100%;
	background-color: #33353d;
}
.social-icons {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content:center;
	        justify-content:center;
	list-style: none;
	margin-top:10px;
}

.follow-item
{
	padding:5px;
}

.social-icons a {
	background-color: #eceeef;
	color: #818a91;
	font-size: 16px;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	margin-right: 8px;
	border-radius: 100%;
	transition: all 0.2s linear;
} 
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
}
.social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.social-icons a.facebook:hover {
	background-color: #3b5998;
}
.social-icons a.twitter:hover {
	background-color: #00aced;
}
.social-icons a.linkedin:hover {
	background-color: #007bb6;
}
.social-icons a.instagram:hover {
	background-color: #e95950;
}

.copyright-text {
	margin-top: 25px;
	margin-bottom: 0px;
	text-align: center;
	font-size: 15px;
}

.divSvg {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: black;
}

#layer3 {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(
		180deg,
		black 81%,
		#ffffff 91%,
		rgb(255, 255, 255)
	);
	-webkit-animation: divsvg1 4s linear normal;
	animation: divsvg1 4s linear normal;
}
#logo {
	position: absolute;
	top: 40%;
	left: 25%;
	-webkit-transform-origin: center center;
	        transform-origin: center center;
	-webkit-animation: upDown 3s linear infinite 7s;
	        animation: upDown 3s linear infinite 7s;
	width: 50%;
	-webkit-animation: fill 5s ease forwards 4s;
	        animation: fill 5s ease forwards 4s;
}

#logo path {
	stroke: white;
	stroke-opacity: 1;
}

#logo path {
	stroke-dasharray: 12434.6650390625;
	stroke-dashoffset: 12434.6650390625;
	-webkit-animation: line-anim 8s ease-in-out forwards;
	        animation: line-anim 8s ease-in-out forwards;
}

@-webkit-keyframes line-anim {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes line-anim {
	to {
		stroke-dashoffset: 0;
	}
}

#shadow {
	position: absolute;
	top: -5%;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 50%;
	background: -webkit-linear-gradient(
		right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	clip-path: polygon(50% 0%, 100% 80%, 100% 99%, 0 99%, 0 79%);
	-webkit-clip-path: polygon(50% 0%, 100% 80%, 100% 99%, 0 99%, 0 79%);
	-webkit-animation: blinker 4s ease normal;
	animation: blinker 4s ease normal;
}

#divContainingShawdow {
	-webkit-transform-origin: top;
	        transform-origin: top;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-animation: ray_anim 3s linear normal 2s;
	animation: ray_anim 3s linear normal 2s;
}
@-webkit-keyframes fill {
	0% {
		fill: transparent;
	}
	100% {
		fill: white;
	}
}
@keyframes fill {
	0% {
		fill: transparent;
	}
	100% {
		fill: white;
	}
}
@-webkit-keyframes lumino {
	0%,
	18%,
	20%,
	50.1%,
	60%,
	65%,
	80.1%,
	90%,
	92.1%,
	100% {
		stroke: #0e3742;
		text-shadow: none;
	}
	18.1%,
	20.1%,
	30%,
	50%,
	60.1%,
	65%,
	80.1%,
	90%,
	92.1%,
	100% {
		stroke: #fff;
		text-shadow: 0 0 10px #03bcf4, 0 0 20px #03bcf4, 0 0 40px #03bcf4,
			0 0 80px #03bcf4, 0 0 160px #03bcf4;
	}
}
@keyframes lumino {
	0%,
	18%,
	20%,
	50.1%,
	60%,
	65%,
	80.1%,
	90%,
	92.1%,
	100% {
		stroke: #0e3742;
		text-shadow: none;
	}
	18.1%,
	20.1%,
	30%,
	50%,
	60.1%,
	65%,
	80.1%,
	90%,
	92.1%,
	100% {
		stroke: #fff;
		text-shadow: 0 0 10px #03bcf4, 0 0 20px #03bcf4, 0 0 40px #03bcf4,
			0 0 80px #03bcf4, 0 0 160px #03bcf4;
	}
}
@-webkit-keyframes ray_anim {
	0% {
		-webkit-transform: rotate(0deg) translateZ(0);
		        transform: rotate(0deg) translateZ(0);
	}
	25% {
		-webkit-transform: rotate(4deg) translateZ(0);
		        transform: rotate(4deg) translateZ(0);
	}
	50% {
		-webkit-transform: rotate(0deg) translateZ(0);
		        transform: rotate(0deg) translateZ(0);
	}
	75% {
		-webkit-transform: rotate(-4deg) translateZ(0);
		        transform: rotate(-4deg) translateZ(0);
	}
	100% {
		-webkit-transform: rotate(0deg) translateZ(0);
		        transform: rotate(0deg) translateZ(0);
	}
}
@keyframes ray_anim {
	0% {
		-webkit-transform: rotate(0deg) translateZ(0);
		        transform: rotate(0deg) translateZ(0);
	}
	25% {
		-webkit-transform: rotate(4deg) translateZ(0);
		        transform: rotate(4deg) translateZ(0);
	}
	50% {
		-webkit-transform: rotate(0deg) translateZ(0);
		        transform: rotate(0deg) translateZ(0);
	}
	75% {
		-webkit-transform: rotate(-4deg) translateZ(0);
		        transform: rotate(-4deg) translateZ(0);
	}
	100% {
		-webkit-transform: rotate(0deg) translateZ(0);
		        transform: rotate(0deg) translateZ(0);
	}
}

@-webkit-keyframes blinker {
	0%,
	8%,
	16%,
	24%,
	32%,
	40%,
	48%,
	56% {
		opacity: 0%;
	}
	4%,
	12%,
	20%,
	28%,
	36%,
	44%,
	52%,
	60% {
		opacity: 20%;
	}
	80% {
		opacity: 23%;
	}
	90% {
		opacity: 27%;
	}
	100% {
		opacity: 31%;
	}
}

@keyframes blinker {
	0%,
	8%,
	16%,
	24%,
	32%,
	40%,
	48%,
	56% {
		opacity: 0%;
	}
	4%,
	12%,
	20%,
	28%,
	36%,
	44%,
	52%,
	60% {
		opacity: 20%;
	}
	80% {
		opacity: 23%;
	}
	90% {
		opacity: 27%;
	}
	100% {
		opacity: 31%;
	}
}
@-webkit-keyframes divsvg1 {
	0%,
	8%,
	16%,
	24%,
	32%,
	40%,
	48%,
	56% {
		opacity: 0;
	}
	4%,
	12%,
	20%,
	28%,
	36%,
	44%,
	52%,
	60%,
	80%,
	90%,
	100% {
		opacity: 1;
	}
}
@keyframes divsvg1 {
	0%,
	8%,
	16%,
	24%,
	32%,
	40%,
	48%,
	56% {
		opacity: 0;
	}
	4%,
	12%,
	20%,
	28%,
	36%,
	44%,
	52%,
	60%,
	80%,
	90%,
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes upDown {
	33% {
		-webkit-transform: translate(0, 10px);
		        transform: translate(0, 10px);
	}
	66% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(0, -10px);
		        transform: translate(0, -10px);
	}
}
@keyframes upDown {
	33% {
		-webkit-transform: translate(0, 10px);
		        transform: translate(0, 10px);
	}
	66% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		-webkit-transform: translate(0, -10px);
		        transform: translate(0, -10px);
	}
}
@media all and (max-width: 986px) {
	#logo {
		width: 65%;
		height: 50%;
		top: 30%;
	}
	.divSvg {
		height: 50%;
	}
}

/* This is the css for heading only */

/* The css below is used to create the grid */

#aboutus-mainheading{
	font-size: 90px;
}
.about {
	width: 100vw;
	z-index: 0;
	padding:0;
	margin:0;
}
.content {
	margin:0;
	padding: 100px 0px;
	text-align: center;
}
.content > h1 {
	color: #141a46;
	font-size: 50px;
	/* text-decoration: underline; */
	text-align: center;
}
.content p {
	text-align: center;
	padding: 0 10px;
	line-height: 25px;
	margin: auto;
}
.about-para{
	color: #141a46;
	font-weight: 600;
	height:auto;
	font-size: 20px;
	word-spacing: 4px;
}
 

 .content {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 600px -webkit-max-content;
	grid-template-rows: 600px max-content;
} 
.content-1{
background:linear-gradient(180deg, white,#FFFAFA);
}
.content-2{
	background-color:whitesmoke;
    padding-top: 80px;

}
.about-boxes{
	position: relative;
	z-index:1;
	width:800px;
	height:130px;
	margin:-760px auto 760px auto;
	background-color:whitesmoke;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: auto auto auto;
	box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
 
.about-box-1 ,.about-box-2{
	border-right: 1px solid grey;
}

#our-tagline{
	font-family: 'Special Elite' ;
	color: #3f3f5a;
	font-style: normal;
	font-weight: 100;
	font-size: 2.5rem;
	line-height: 1.4;
	margin:60px;
}
#about-headings{
	color:black;
	font-family:'Gill Sans', 'Gill Sans MT' , 'Trebuchet MS', sans-serif;
	font-style: normal;
	margin:40px;
	font-size: 40px;
}
@media all and (max-width: 986px) {
	.content {
		padding:0;
		display: grid;
		grid-auto-flow: row;
		grid-template-rows: 300px -webkit-max-content;
		grid-template-rows: 300px max-content;
	}
	.content-2{
		padding-top: 0;
	}
	#aboutus-mainheading{
		font-size: 45px;
	}

	#our-tagline{
		font-weight: 100;
		font-size: 1rem;
		line-height: 1.4;
		margin:30px;
	}

	#about-headings{
		margin:20px;
		font-size: 25px;
	}
	
	.about-boxes{
		position:inherit;
	    z-index:0;
		background-color:whitesmoke;
		width:100vw;
		height:400px;
		margin:0;
		margin-top: 30px;
		margin-bottom: 30px;
		padding:0;
		display: grid;
		text-align: center;
		grid-template-rows: auto auto auto;
		grid-template-columns: auto;
		box-shadow: none;
	}
	.about-boxes p{
		color: black;
	}
	.about-box-1 ,.about-box-2{
		-webkit-align-items: center;
		        align-items: center;
		border-right:0;
	}
	.about-para-2
	{
		margin-top:50px;
	}
	.about-para-3
	{
		margin-top:50px;
	}
}
 
.f-card {
	display: grid;
	grid-template-columns: 300px;
	grid-template-rows: 115px 100px 280px;
	background-color: transparent;
	width: 300px;
}


.f-image {
	grid-column: 1;
	grid-row: 1/3;
	border-radius: 50%;
	border-width: 10px;
	border-style: solid; 
    border-color:#1c1d21;
	z-index: 1;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 170px; 
	
}

.f-inner-card {
	grid-column: 1;
	grid-row: 2/4;
	border-radius: 10px;
	transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d; 
}

.f-front {
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 10px;
	text-align: center;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-style: double;
	border-color: #f7f7f7; 
	background-color: black;
	opacity: 0.5;
	border-width: 3px;
}

.f-back {
	width: 100%;
	height: 100%;	
	position: absolute;
	border-radius: 10px;
	text-align: center;
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-style: double;
	border-color: #f7f7f7;
	background-color: black;
	opacity: 0.5;
}
.f-card:hover .f-inner-card {
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
}
.f-name {
	margin-top: 100px;
	font-size: 20px;
	color: white;
	font-family: cursive;

}

.f-position {
	font-size: 30px;
	margin-top: 0;
	font-weight: 700;
	color: white;
	font-family: cursive;

}

.f-bio {
	font-style: italic;
	font-size: 20px;
	color: white;
	font-family: cursive;
}
.b-about {
	margin-top: 100px;
	font-size: 20px;
	font-weight: 500;
	color: white;

}

.b-social-icons {
	padding-left: 0;
	margin-bottom: 5px;
	list-style: none;
	margin: 0 auto;
}
.b-social-icons li {
	display: inline-block;
	margin-bottom: 4px;
}
.b-social-icons a {
	background-color: #eceeef;
	color: #818a91;
	font-size: 16px;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	margin-right: 8px;
	border-radius: 100%;
	transition: all 0.2s linear;
}
.b-social-icons a:active,
.b-social-icons a:focus,
.b-social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
}
.b-social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.b-social-icons a.facebook:hover {
	background-color: #3b5998;
}
.b-social-icons a.twitter:hover {
	background-color: #00aced;
}
.b-social-icons a.linkedin:hover {
	background-color: #007bb6;
}
.b-social-icons a.instagram:hover {
	background-color: #e95950;
}

.nav {
	text-align: center;
	background: transparent;
	color: white;
	font-size: 30px;
	height: 70%;
	z-index: 1;
	position: absolute;
	left: 0;
	width: 100%;
}

.containingbutton {
	text-align: right;
	padding-top: 1rem;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
.containingbutton button {
	text-align: center;
	max-width: -webkit-max-content;
	max-width: max-content;
}

.header {
	text-align: left;
	font-family: "Roboto", Cambria, Cochin, Georgia, Times, "Times New Roman",
		serif;
}

.containbuttonend {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.main-page {
	width: 100vw;
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 100vh -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 100px;
	grid-template-rows: 100vh max-content max-content max-content max-content 100px;
}

.four-cards {
	margin: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	/*background-color: #f7f7f7;*/
	/*background-color: black;*/
	background: #1c1d21;
	padding-top: 20px;
	padding-bottom: 70px;
	background: #1c1d21;
}
.para-team {
	text-align: center;
	background: #1c1d21;
}
#team {
	color: white;
	font-family: Georgia, "Times New Roman", Times, serif;
	font-size: 40px;
}
@media all and (max-width: 986px) {
	.main-page {
		grid-template-rows: 320px -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 100px;
		grid-template-rows: 320px max-content max-content max-content max-content 100px;
	}
}



.h-image
{
    height: 50%;
    width:50%;
    float:left;
}

.h-text
{
    font-size:40px;
    padding-top:120px;
    text-align:center;
    color:black;
}

.h-quote {
	font-size: 40px;
	display: inline-block;
}

@media(max-width:986px){

  /* This resizes the divs that contain your content to fill the width of the page so that they stack vertically */
  .h-image
{
    height: 100%;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    float:none;
    display:block;
}
  
.h-text
{
    font-size:30px;
    padding-top:10px;
    text-align:center;
}

    .h-quote { 
        font-size: 30px;
        display: inline-block;
    }
    
  .handd
  {
      margin-bottom:30px;
  }  
}
:root {
  --primary: #1888ff; 
}

.btn-signout {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: #1888ff;
  background-color: var(--primary);
}

.btn-signout:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid #1888ff;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
@media screen and (max-width: 960px)
{
  .btn-signout {
		display: none;
	}
}
.navbar {
	background: linear-gradient(
		90deg,
		rgb(28, 27, 27) 0%,
		rgb(26, 23, 23) 100%
	);
	height: 80px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 1.2rem;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
}

.fa-firstdraft {
	margin-left: 0.5rem;
	font-size: 1.6rem;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 70vw;
	-webkit-justify-content: end;
	        justify-content: end;
	margin-right: 2rem;
}

.nav-item {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	height: 80px;
}

.nav-links {
	color: white;
	text-decoration: none;
	padding: 0.5rem 1rem;
}

.nav-links:hover {
	background-color: #1888ff;
	border-radius: 4px;
	transition: all 0.2s ease-out;
}

.fa-bars {
	color: #fff;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		width: 100%;
		height: 90vh;
		position: absolute;
		top: 80px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu.active {
		background: #242222;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		background-color: #1888ff;
		border-radius: 0;
	}

	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		-webkit-transform: translate(25%, 50%);
		        transform: translate(25%, 50%);
	}

	.menu-icon { 
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		-webkit-transform: translate(-100%, 60%);
		        transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #fff;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		padding: 1.5rem;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		background: #1888ff;
		text-decoration: none;
		color: #fff;
		font-size: 1.5rem;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #1888ff;
		transition: 250ms;
	}

	
}

.dropdown-menu {
  background: red;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #1888ff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

:root {
	--image-width: 50vw;
	--card-width: 50vw;
}

.logo_container {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 600px 200px;
}

.Login {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 50vw 50vw;
	grid-template-columns: var(--image-width) var(--card-width);
	-webkit-align-content: center;
	        align-content: center;
}
.loginImg {
	width: 50vw;
	width: var(--image-width);
}
.loginCard {
	text-align: center;
	border-radius: 10px 10px 10px 10px;
	width: 450px;
	padding-bottom: 70px;
	margin: 0 auto;
	background: linear-gradient(#141e30, #243b55);
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.loginCard:hover {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-2 {
	width: 450px;
	margin: 20px auto;
	padding: 10px;
	border-radius: 10px 10px 10px 10px;
	text-align: center;
	background: linear-gradient(#141e30, #243b55);
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card-2:hover {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.input {
	height: 35px;
	width: 300px;
	border: rgb(39, 185, 204);
	outline: none;
	border-radius: 4px;
	transition: all 0.5s;
	color: black;
	font-size: 15px;
}
.input:focus,
.input:active {
	box-shadow: 1px 1px 18px #3399ff;
}
::-webkit-input-placeholder {
	opacity: 0.5;
}
:-ms-input-placeholder {
	opacity: 0.5;
}
::placeholder {
	opacity: 0.5;
}

.companyName {
	font-family: "Brush Script MT", cursive;
	font-size: 60px;
	padding: 20px;
	color: white;
}
.lgn-eye > .eye-img {
	cursor: pointer;
	width: 25px;
	fill: #000;
}
.passContainer {
	position: relative;
}
.para {
	font-size: 20px;
	color: white;
}
a {
	color: lightblue;
	text-decoration: none;
}

.lgn-passwd-container {
	width: -webkit-max-content;
	width: max-content;
	height: -webkit-max-content;
	height: max-content;
	margin: 40px auto 20px;
	position: relative;
}
.lgn-form {
	margin-top: 60px;
}

@media (max-width: 1000px) {
	.loginImg {
		display: none;
	}
	.loginCard {
		margin-top: 30px;
	}
	.Login {
		display: block;
		background: url(/static/media/login.0de96a59.png);
		background-size: cover;
		background-repeat: no-repeat;
	}
}
@media (max-width: 700px) {
	.Login {
		background-image: none;
		background: transparent;
	}
	.logo_container {
		background-image: linear-gradient(#141e30, #243b55);
	}
	.loginCard {
		background: rgba(0, 0, 0, 0.5);
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
	}
	.card-2 {
		background: rgba(0, 0, 0, 0.5);
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
	}
}
@media (max-width: 500px) {
	.loginCard {
		width: 300px;
	}
	.input {
		width: 200px;
	}
	.companyName {
		font-size: 50px;
	}
	.card-2 {
		width: 300px;
	}
}

:root {
	--image-height: 88vh;
	--fields-height: 30px;
}
.sgn-formContainer {
	margin: 20px;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 50% 50%;
	grid-template-rows: 88vh;
	grid-template-rows: var(--image-height);
}

.sgn-image img {
	width: 100%;
	height: 88vh;
	height: var(--image-height);
	min-height: 500px;
}

.sgn-form {
	margin: auto;
	width: 100%;
	text-align: center;
}

.sgn-box {
	border: 1px solid black;
	text-align: left;
	padding: 50px;
	width: -webkit-max-content;
	width: max-content;
	margin: 0 auto;
	border-radius: 20px;
	box-shadow: grey 5px 10px;
	background: linear-gradient(#141e30, #243b55);
	background-color: rgba(0, 0, 0, 0.5);
}

.sgn-text-box {
	color: white;
	font-size: 14px;
	font-family: "Courier New", Courier, monospace;
}
.sgn-text-box,
select {
	width: 250px;
	height: 30px;
	height: var(--fields-height);
	margin: 5px 0;
	color: whitesmoke;
}
select {
	font-size: 14px;
	font-family: "Courier New", Courier, monospace;
}
option {
	background-color: #141e30;
	font-size: 14px;
	opacity: 0.5;
	font-family: "Courier New", Courier, monospace;
}

.sgn-text-box,
#currYear {
	border: none;
	outline: 0;
	border-bottom: 1px solid whitesmoke;
	transition-duration: 2100ms;
	background: transparent;
	/* transition-property: ease-in-out; */
}

.sgn-text-box:focus {
	outline: none;
	caret-color: rgb(230, 174, 70);
	border-color: green;
}
.sgn-text-box::-webkit-autofill {
	border-radius: 20px;
}
label:not(.sgn-eye, .lgn-eye) {
	display: none;
}

.passwd-container {
	position: relative;
	/* border: 1px solid black; */
}
.eye-img {
	position: absolute;
	top: 0%;
	right: 0%;
}
.sgn-text-box::-webkit-input-placeholder {
	color: whitesmoke;
	font-size: 14px;
	opacity: 0.5;
	font-family: "Courier New", Courier, monospace;
}
.sgn-text-box:-ms-input-placeholder {
	color: whitesmoke;
	font-size: 14px;
	opacity: 0.5;
	font-family: "Courier New", Courier, monospace;
}
.sgn-text-box::placeholder {
	color: whitesmoke;
	font-size: 14px;
	opacity: 0.5;
	font-family: "Courier New", Courier, monospace;
}
.btn-std {
	min-width: -webkit-max-content;
	min-width: max-content;
}
.sgn-main_container {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 650px 200px;
}
h1,
p {
	padding: 10px;
}

@media (max-width: 982px) {
	.sgn-formContainer {
		display: block;
		background-image: url(/static/media/login.0de96a59.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
	.sgn-image {
		display: none;
	}
}

@media (max-width: 600px) {
	.sgn-main_container {
		background-image: linear-gradient(#141e30, #243b55);
	}
	.sgn-formContainer {
		background-image: none;
		background: transparent;
	}
	.sgn-form h1,
	p {
		color: whitesmoke;
	}
	.sgn-box {
		background: rgba(0, 0, 0, 0.5);
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
	}
}

@media (max-width: 550px) {
	.sgn-form > h1 {
		font-size: 20px;
	}
	.sgn-form,
	.btn-std {
		font-size: 10px;
	}
}

@media (min-height: 700px) {
	.sgn-main_container {
		grid-template-rows: 88vh 7vh;
		grid-row-gap: 5vh;
	}
}

@media (min-width: 1500px) {
	.sgn-formContainer {
		grid-template-columns: 40% 60%;
	}
	.sgn-text-box,
	select {
		width: 25vw;
		font-size: 30px;
		height: 50px;
	}
	.sgn-form > h1 {
		font-size: 60px;
	}
	.sgn-form > p {
		font-size: 30px;
	}
}
@media (max-width: 400px) {
	.sgn-text-box,
	select {
		width: 150px;
	}
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.categories,
.companies,
.about-us,
.sign-out,
.core,
.consultancy {
  display: -webkit-flex;
  display: flex;
  height: 90vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 3rem;
}

.home {
  background-image: url(/static/media/img-1.d8b66c08.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.categories {
  background-image: url(/static/media/img-2.606518a3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.companies {
  background-image: url(/static/media/img-3.574f1042.jpg);
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about-us {
  background-image: url(/static/media/img-4.39d0b055.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-out {
  background-image: url(/static/media/img-7.ebf241d1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.core {
  background-image: url(/static/media/img-6.190d0057.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consultancy {
  background-image: url(/static/media/img-5.eae59fb6.jpg);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.phoneviewcategories{
    display:none;
}
@media screen and (max-width: 960px) {
    .phoneviewcategories{
        display:list-item;
    }
    body {
        font-family: sans-serif;
        font-size: 1em;
        color: #333;
        background: #f7f7f7;
      }
      .phoneviewcategories h1 {
        color: #0074D9;
        }
        
        .phoneviewcategories ul,
        li {
          padding: 0;
          margin: 0;
          list-style: none;
        }
        
        .phoneviewcategories ul {
          margin: 2em 0;
        }
        
        .phoneviewcategories li {
          margin: 1em;
          margin-left: 3em;
        }
        
        .phoneviewcategories li:before {
          content: '\f006';
          font-family: 'FontAwesome';
          float: left;
          margin-left: -1.5em;
          color: #0074D9;
        }
  }
.poster {
	/* width: 100vw; */
	margin-left:100px; 
	-webkit-align-items: center; 
	        align-items: center;
	 
}

.pos-name
{
	text-align: center;
	font-size: 26px;
	font-weight: bold;
}

.pos-imag {  
	margin-top: 20px;
	height: 75vh; 
	width: 85%;
	border-radius: 3%;
	display: block;
	margin-left: auto;
	margin-right: 11%;
	-webkit-animation-name: imagepreshift;
	        animation-name: imagepreshift;
	-webkit-animation-duration: 0.25s;
	        animation-duration: 0.25s;
  }

.imshift
{
	margin-right:1%;
	-webkit-animation-name: imageshift;
	        animation-name: imageshift;
	-webkit-animation-duration: 0.25s;
	        animation-duration: 0.25s;
}  

@-webkit-keyframes imageshift {
	from {
		margin-right:11%;
	}
}  

@keyframes imageshift {
	from {
		margin-right:11%;
	}
}

@-webkit-keyframes imagepreshift {
	from {
		margin-right:1%;
	}
}

@keyframes imagepreshift {
	from {
		margin-right:1%;
	}
}

.img-footer
{
	display:-webkit-flex;
	display:flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content:space-between;
	        justify-content:space-between;
}

.pos-but
{
	 margin-right:12%;
	 height:50px;
	 width:100px;
	 padding: 10px;
	 margin-top:12px;
	 cursor: pointer;
	 background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%);
	 color: #fff;
	 outline: 0;
	 border: none;
	 border-radius: 10px; 
	 -webkit-animation-name: butpreshift; 
	         animation-name: butpreshift;
	 -webkit-animation-duration: 0.25s;
	         animation-duration: 0.25s;
}

.but-shift
{
	margin-right:1%;
	-webkit-animation-name: butshift;
	        animation-name: butshift;
	-webkit-animation-duration: 0.25s;
	        animation-duration: 0.25s;
}

@-webkit-keyframes butshift {
	from {
		margin-right:12%;
	}
}

@keyframes butshift {
	from {
		margin-right:12%;
	}
}

@-webkit-keyframes butpreshift {
	from {
		margin-right:1%;
	}
}

@keyframes butpreshift {
	from {
		margin-right:1%;
	}
}
.gd-social-icons {
	margin-left: 30px;
	margin-right: auto;
	margin-bottom: 5px;
	margin-top:10px;
	list-style: none; 
	-webkit-animation-name: nashift; 
	        animation-name: nashift;
	-webkit-animation-duration: 0.25s;
	        animation-duration: 0.25s;
}

.socshift
{
	margin-left:155px;
	-webkit-animation-name: napreshift;
	        animation-name: napreshift;
	-webkit-animation-duration: 0.25s;
	        animation-duration: 0.25s
}

@-webkit-keyframes nashift {
	from {
		margin-left: 155px;
	}
	 
}

@keyframes nashift {
	from {
		margin-left: 155px;
	}
	 
}

@-webkit-keyframes napreshift {
	from {
		margin-left: 30px;
	}
	 
}

@keyframes napreshift {
	from {
		margin-left: 30px;
	}
	 
}

.gd-social-icons li {
	display: inline-block;
	margin-bottom: 15px;
	padding: 7px;
}
.gd-social-icons li.title {
	text-transform: uppercase;
	color: #96a2b2;
	font-weight: 700;
	font-size: 13px;
}

.gd-social-icons a {
	font-size: 16px;
	background-color:white;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	border-radius: 100%;
	border: 2px solid;
	border-color: grey;
	transition: all 0.2s linear;
}

.gd-social-icons a.gd-facebook  {
	color: #3b5998;
}
.gd-social-icons a.gd-twitter  {
	 color: #00aced; 
}
.gd-social-icons a.gd-linkedin  {
	color: #007bb6; 
}
.gd-social-icons a.gd-instagram {
	 color: #e95950; 
}

.gd-social-icons a:active,
.gd-social-icons a:focus,
.gd-social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
	 
}
.gd-social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.gd-social-icons a.gd-facebook:hover {
	background-color: #3b5998;
}
.gd-social-icons a.gd-twitter:hover {
	background-color: #00aced;
}
.gd-social-icons a.gd-linkedin:hover {
	background-color: #007bb6;
}
.gd-social-icons a.gd-instagram:hover {
	background-color: #e95950;
}

.upbut
{
  -webkit-transform-style: flat;
          transform-style: flat;
  transition: all 250ms ease-out;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(128, 128, 128, 0.1) inset;
 
}

.upbut::before, 
.upbut::after
{
	content: "";
	position: absolute;
	z-index: -2;
	transition: all 250ms ease-out;
}

.upbut::before
{ 
	top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
}

.upbut:hover
{  
	box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
}
.poster-grid {
	display: grid;
	grid-auto-flow: row;
	grid-row-gap: 60px;
	row-gap: 60px;
	margin-top: 50px;
	margin-bottom: 50px; 
}
 
.sidebutton {
	width: 130px;
	height: 50px;
	font-size: 18px;
	background: #4a4788;
	color: #fbfcfc;
	box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
	border: none;
	border-radius: 10px; 
	position: fixed;
	top: 82px;
	left:2px;
	cursor: pointer;
	outline: 0;
	transition: all 0.25s ease;

}

.sidebutton:hover {
	color: #fbfcfc;
	 
	opacity: 0.9;
	transition: all 0.25s ease;
}



.munubutton
{
	width: 86px;
	height: 40px;
	float: right;
	cursor: pointer;
	transition: all 0.25s ease;
	background:transparent;
	outline: none;
	border:0px;
	margin:5px;
	 
}

.munubutton:hover {
	opacity: 0.8;
	transition: all 0.25s ease;
	background: #0f0c56;
	border-radius:20px;
}


.munubut
{
	border-bottom: 0px;
	height:40px;
}

.munu {
	background:#4e4f8f;
	height: 100vh;
	width: 250px;
	opacity: 0;
	position: fixed;
	top:80px;
	visibility: hidden;
	transition: all 0.25s ease; 
}

.munu a {
	text-align: center;
	color: #a4abb6;
	text-decoration: none;
	display: block;
	padding: 20px;
}

.munu ul {
	padding: 0;
	margin: 0;
}

.munuli
{
	font-size: 16px; 
	transition: all 0.25s ease;
	-webkit-animation: fadeInRight 0.25s ease forwards;
	        animation: fadeInRight 0.25s ease forwards;
	opacity: 0;
}

.munuli:hover {
	opacity: 0.8;
	transition: all 0.25s ease;
	background: #0f0c56;
	cursor: pointer;
	border-radius:20px;
}

.munuli:hover a{
	color: #fbfcfc;
	 
}

.activee {
	opacity: 1;
	visibility: visible;
	transition: all 0.25s ease; 
}
 
.post-phone 
{ 
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom:50px; 
    margin-top:50px; 
    background: #ECE9E6;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius:5%;
    padding-top:10px;
}

.phone-poster-image
{ 
    border-radius: 20px;
    height: 80%;
    width:90%;  
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.post-phone-text-1
{
    font-size: 25px;
    margin-left: 7%;
    margin-top:20px;
}
.post-phone-text-1 span
{
    padding :5px;
    background:#e9e9e9 ;
    color:#000000;
    border-radius: 10px;
}

.post-phone-text-2
{
    font-size: 20px;
    margin-left: 7%;
}

.post-phone-text-2 span
{
    padding :5px;
    background:#e9e9e9 ;
    color:#000000;
    border-radius: 10px;
}

.post-phone-but-div
{
    display: -webkit-flex;
    display: flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content:center;
	        justify-content:center;
}

.post-phone-but
{    
	 height:50px;
	 width:100px;
	 padding: 10px;
	 margin-top:12px;
	 cursor: pointer;
	 background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%);
	 color: #fff;
	 outline: 0;
	 border: none;
	 border-radius: 10px; 
}


.gp-handles
{
    display: -webkit-flex;
    display: flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content:center;
	        justify-content:center;
	list-style: none;
	margin-top:15px;
} 
.gp-social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px; 
	border-radius: 100%;
	background-color: #33353d;
}
.gp-social-icons {
	margin-bottom: 5px;
	list-style: none;
}
.gp-social-icons li {
	display: inline-block;
	margin-bottom: 15px;
    margin-right:12px;
}
.gp-social-icons li.title {
	text-transform: uppercase;
	color: #96a2b2;
	font-weight: 700;
	font-size: 13px;
}

.gp-social-icons a {
	font-size: 16px;
	background-color:white;
	font-size: 16px;
	display: inline-block;
	line-height: 44px; 
	width: 44px;
	height: 44px;
	text-align: center;
	border-radius: 100%;
    border: 2px solid;
	border-color: grey;
	transition: all 0.2s linear;
}

.gp-social-icons a.gp-facebook  {
	color: #3b5998;
}
.gp-social-icons a.gp-twitter  {
	 color: #00aced; 
}
.gp-social-icons a.gp-linkedin  {
	color: #007bb6; 
}
.gp-social-icons a.gp-instagram {
	 color: #e95950; 
}

.gp-social-icons a:active,
.gp-social-icons a:focus,
.gp-social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
}
.gp-social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.gp-social-icons a.gp-facebook:hover {
	background-color: #3b5998;
}
.gp-social-icons a.gp-twitter:hover {
	background-color: #00aced;
}
.gp-social-icons a.gp-linkedin:hover {
	background-color: #007bb6;
}
.gp-social-icons a.gp-instagram:hover {
	background-color: #e95950;
}

.poster-grid-phone
{
    margin-top:30px;
    margin-bottom:30px;
} 
body {
	width: 100% !important;
	height: 100% !important;
	margin: 0px !important;
	padding: 0px !important;
	overflow-x: hidden !important;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Space Mono", "Times New Roman", Times, serif;
}
p {
	font-family: Montserrat, "Times New Roman", Times, serif;
}
.loginCard:hover {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.btn-std {
	margin: 10px;
	width: 35%;
	color: #fff;
	border-radius: 5px;
	padding: 10px 25px;
	font-family: "Lato", sans-serif;
	font-weight: 500;
	font-size: 17px;
	background: transparent;
	cursor: pointer;
	transition: all 0.3s ease;
	position: relative;
	display: inline-block;
	box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
		7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
	outline: none;
}
.btn-std {
	background-color: linear-gradient(180deg, transparent, #03e9f4);
	border: none;
	z-index: 1;
}
.btn-std:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	border-radius: 5px;
	background: #03e9f4;

	box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
		7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
	transition: all 0.3s ease;
}
.btn-std:hover {
	color: #fff;
}
.btn-std:hover:after {
	top: 0;
	height: 100%;
}
.btn-std:active {
	top: 2px;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #666;
	border-radius: 10px;
}
::-webkit-scrollbar-track {
	background: #ddd;
}

