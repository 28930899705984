@import url(https://fonts.googleapis.com/css2?family=Space+Mono);
@import url(https://fonts.googleapis.com/css2?family=Montserrat);

body {
	width: 100% !important;
	height: 100% !important;
	margin: 0px !important;
	padding: 0px !important;
	overflow-x: hidden !important;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Space Mono", "Times New Roman", Times, serif;
}
p {
	font-family: Montserrat, "Times New Roman", Times, serif;
}
.loginCard:hover {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.btn-std {
	margin: 10px;
	width: 35%;
	color: #fff;
	border-radius: 5px;
	padding: 10px 25px;
	font-family: "Lato", sans-serif;
	font-weight: 500;
	font-size: 17px;
	background: transparent;
	cursor: pointer;
	transition: all 0.3s ease;
	position: relative;
	display: inline-block;
	box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
		7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
	outline: none;
}
.btn-std {
	background-color: linear-gradient(180deg, transparent, #03e9f4);
	border: none;
	z-index: 1;
}
.btn-std:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	border-radius: 5px;
	background: #03e9f4;

	box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
		7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
	transition: all 0.3s ease;
}
.btn-std:hover {
	color: #fff;
}
.btn-std:hover:after {
	top: 0;
	height: 100%;
}
.btn-std:active {
	top: 2px;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #666;
	border-radius: 10px;
}
::-webkit-scrollbar-track {
	background: #ddd;
}
