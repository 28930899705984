:root {
	--image-height: 88vh;
	--fields-height: 30px;
}
.sgn-formContainer {
	margin: 20px;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 50% 50%;
	grid-template-rows: var(--image-height);
}

.sgn-image img {
	width: 100%;
	height: var(--image-height);
	min-height: 500px;
}

.sgn-form {
	margin: auto;
	width: 100%;
	text-align: center;
}

.sgn-box {
	border: 1px solid black;
	text-align: left;
	padding: 50px;
	width: max-content;
	margin: 0 auto;
	border-radius: 20px;
	box-shadow: grey 5px 10px;
	background: linear-gradient(#141e30, #243b55);
	background-color: rgba(0, 0, 0, 0.5);
}

.sgn-text-box {
	color: white;
	font-size: 14px;
	font-family: "Courier New", Courier, monospace;
}
.sgn-text-box,
select {
	width: 250px;
	height: var(--fields-height);
	margin: 5px 0;
	color: whitesmoke;
}
select {
	font-size: 14px;
	font-family: "Courier New", Courier, monospace;
}
option {
	background-color: #141e30;
	font-size: 14px;
	opacity: 0.5;
	font-family: "Courier New", Courier, monospace;
}

.sgn-text-box,
#currYear {
	border: none;
	outline: 0;
	border-bottom: 1px solid whitesmoke;
	transition-duration: 2100ms;
	background: transparent;
	/* transition-property: ease-in-out; */
}

.sgn-text-box:focus {
	outline: none;
	caret-color: rgb(230, 174, 70);
	border-color: green;
}
.sgn-text-box::-webkit-autofill {
	border-radius: 20px;
}
label:not(.sgn-eye, .lgn-eye) {
	display: none;
}

.passwd-container {
	position: relative;
	/* border: 1px solid black; */
}
.eye-img {
	position: absolute;
	top: 0%;
	right: 0%;
}
.sgn-text-box::placeholder {
	color: whitesmoke;
	font-size: 14px;
	opacity: 0.5;
	font-family: "Courier New", Courier, monospace;
}
.btn-std {
	min-width: max-content;
}
.sgn-main_container {
	display: grid;
	grid-auto-flow: row;
	grid-template-rows: 650px 200px;
}
h1,
p {
	padding: 10px;
}

@media (max-width: 982px) {
	.sgn-formContainer {
		display: block;
		background-image: url("../img/login.png");
		background-size: contain;
		background-repeat: no-repeat;
	}
	.sgn-image {
		display: none;
	}
}

@media (max-width: 600px) {
	.sgn-main_container {
		background-image: linear-gradient(#141e30, #243b55);
	}
	.sgn-formContainer {
		background-image: none;
		background: transparent;
	}
	.sgn-form h1,
	p {
		color: whitesmoke;
	}
	.sgn-box {
		background: rgba(0, 0, 0, 0.5);
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
	}
}

@media (max-width: 550px) {
	.sgn-form > h1 {
		font-size: 20px;
	}
	.sgn-form,
	.btn-std {
		font-size: 10px;
	}
}

@media (min-height: 700px) {
	.sgn-main_container {
		grid-template-rows: 88vh 7vh;
		grid-row-gap: 5vh;
	}
}

@media (min-width: 1500px) {
	.sgn-formContainer {
		grid-template-columns: 40% 60%;
	}
	.sgn-text-box,
	select {
		width: 25vw;
		font-size: 30px;
		height: 50px;
	}
	.sgn-form > h1 {
		font-size: 60px;
	}
	.sgn-form > p {
		font-size: 30px;
	}
}
@media (max-width: 400px) {
	.sgn-text-box,
	select {
		width: 150px;
	}
}
