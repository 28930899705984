:root {
  --primary: #1888ff; 
}

.btn-signout {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn-signout:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
@media screen and (max-width: 960px)
{
  .btn-signout {
		display: none;
	}
}