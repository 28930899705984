.post-phone 
{ 
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom:50px; 
    margin-top:50px; 
    background: #ECE9E6;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius:5%;
    padding-top:10px;
}

.phone-poster-image
{ 
    border-radius: 20px;
    height: 80%;
    width:90%;  
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.post-phone-text-1
{
    font-size: 25px;
    margin-left: 7%;
    margin-top:20px;
}
.post-phone-text-1 span
{
    padding :5px;
    background:#e9e9e9 ;
    color:#000000;
    border-radius: 10px;
}

.post-phone-text-2
{
    font-size: 20px;
    margin-left: 7%;
}

.post-phone-text-2 span
{
    padding :5px;
    background:#e9e9e9 ;
    color:#000000;
    border-radius: 10px;
}

.post-phone-but-div
{
    display: flex;
	flex-flow: row wrap;
	justify-content:center;
}

.post-phone-but
{    
	 height:50px;
	 width:100px;
	 padding: 10px;
	 margin-top:12px;
	 cursor: pointer;
	 background-image: linear-gradient(to right, #232526 0%, #414345  51%, #232526  100%);
	 color: #fff;
	 outline: 0;
	 border: none;
	 border-radius: 10px; 
}


.gp-handles
{
    display: flex;
	flex-flow: row wrap;
	justify-content:center;
	list-style: none;
	margin-top:15px;
} 
.gp-social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px; 
	border-radius: 100%;
	background-color: #33353d;
}
.gp-social-icons {
	margin-bottom: 5px;
	list-style: none;
}
.gp-social-icons li {
	display: inline-block;
	margin-bottom: 15px;
    margin-right:12px;
}
.gp-social-icons li.title {
	text-transform: uppercase;
	color: #96a2b2;
	font-weight: 700;
	font-size: 13px;
}

.gp-social-icons a {
	font-size: 16px;
	background-color:white;
	font-size: 16px;
	display: inline-block;
	line-height: 44px; 
	width: 44px;
	height: 44px;
	text-align: center;
	border-radius: 100%;
    border: 2px solid;
	border-color: grey;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.gp-social-icons a.gp-facebook  {
	color: #3b5998;
}
.gp-social-icons a.gp-twitter  {
	 color: #00aced; 
}
.gp-social-icons a.gp-linkedin  {
	color: #007bb6; 
}
.gp-social-icons a.gp-instagram {
	 color: #e95950; 
}

.gp-social-icons a:active,
.gp-social-icons a:focus,
.gp-social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
}
.gp-social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.gp-social-icons a.gp-facebook:hover {
	background-color: #3b5998;
}
.gp-social-icons a.gp-twitter:hover {
	background-color: #00aced;
}
.gp-social-icons a.gp-linkedin:hover {
	background-color: #007bb6;
}
.gp-social-icons a.gp-instagram:hover {
	background-color: #e95950;
}
